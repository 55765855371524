/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="testimonial-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Testimonial</h3>
      <div class="widget">
        <div class="testimonial-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="updateTestimonial"
            @reset.prevent="resetForm()"
          >
            <div>
              <div class="font-18 py-3">
                Information
                <span class="font-12">(* Required information)</span>
              </div>
              <!-- name -->
              <div>
                <b-form-group>
                  <label>Name*</label>
                  <b-input
                    v-model="$v.testimonial.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-name-live-feedback"
                    placeholder="Enter Name"
                  ></b-input>

                  <b-form-invalid-feedback id="input-name-live-feedback"
                    >Name is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- content -->
              <div class="pt-3">
                <b-form-group>
                  <label>Content*</label>
                  <br />
                  <b-form-textarea
                    v-model="$v.testimonial.content.$model"
                    :state="validateState('content')"
                    aria-describedby="input-content-live-feedback"
                    id="textarea"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>

                  <b-form-invalid-feedback id="input-content-live-feedback"
                    >Content is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- attachment -->
              <div class="pt-2">
                <b-form-group>
                  <label>Attachment*</label>
                  <br />
                  <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput1.click()"
                    >Upload</b-button
                  >
                  <input
                    ref="fileInput1"
                    type="file"
                    accept="image/jpeg"
                    multiple
                    style="display:none;"
                    @change="onCardChange($event.target.name, $event.target.files)"
                  />
                  <div
                    v-if="
                      !$v.testimonial.attachment.required &&
                        $v.testimonial.attachment.$error &&
                        $v.testimonial.attachment.$touch
                    "
                    class="red error-text py-1"
                  >
                    Attachment is required
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-row gutters>
                  <b-col md="2" cols="4" v-for="(image, key) in cardImg" class="my-2" :key="image">
                    <div>
                      <div>
                        <div class="icon-section">
                          <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="red font-12"
                            @click="removeCard(key)"
                          />
                        </div>
                        <img :src="image" alt="your image" class="img-fluid images ml-3" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">{{ message }}</div>
            <div class="d-flex justify-content-center pt-3">
              <div>
                <b-button variant="success" type="submit">Update</b-button>
              </div>
              <div>
                <b-button variant="danger" type="reset" class="ml-5">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    headerCustom
  },
  data() {
    return {
      testimonial: {
        name: "",
        content: "",
        attachment: []
      },
      cardImg: [],

      showError: false,
      message: ""
    };
  },
  validations: {
    testimonial: {
      name: {
        required
      },
      content: {
        required
      },
      attachment: {
        required
      }
    }
  },
  created() {
    this.testimonialId = this.$route.params.id;
    if (this.testimonialId) {
      this.getOneTestimonial();
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.testimonial[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.$router.push({ name: "viewTestimonial" });
    },
    removeCard(image) {
      this.cardImg.pop(image);
    },
    onCardChange(coverName, coverList) {
      if (this.cardImg.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
      } else {
        this.coverFile = coverList[0];
        const coverSize = this.coverFile.size / 1024;
        if (coverSize > 1000) {
          //cover ka 500kb
          this.errorDialog = true;
          this.errorText = "File Size should not be greater than 1mb.";
          this.$toaster.error("File Size should not be greater than 1mb.");
          return;
        }
        if (this.coverFile.type == "image/jpeg" || this.coverFile.type == "image/jpg") {
          const fd = new FormData();
          fd.append("file", this.coverFile, this.coverFile.name);
          service.awscoverImageUpload(fd, awsResponse => {
            if (awsResponse.status === 200) {
              const predictionResults = awsResponse.data;
              this.s3URL = predictionResults.file;
              this.cardImg.push(this.s3URL);
              this.testimonial.attachment = this.cardImg;
            } else {
              this.$toaster.error("Card Image Not Saved");
            }
          });
        } else {
          this.$toaster.error("Image should be in JPEG format only");
        }
      }
    },
    updateTestimonial() {
      this.$v.testimonial.$touch();
      if (this.$v.testimonial.$anyError) {
        return;
      } else {
        return new Promise((resolve, reject) => {
          service.updateTestimonial(this.testimonial, this.testimonialId, resultData => {
            if (resultData) {
              resolve();
              this.$toaster.success("Testimonial updated");
              this.$router.push({ name: "viewTestimonial" });
            } else {
              reject();
            }
          });
        });
      }
    },
    getOneTestimonial() {
      service.getOneTestimonial(this.testimonialId, result => {
        this.testimonial = result.data;
        this.cardImg = result.data.attachment;
      });
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.testimonial-section {
  .testimonial-content {
    padding: 20px 20px;
  }
}
.red {
  color: red;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
